body {
  margin: 0;
  background-color: #525659;
  font-family: Segoe UI, Tahoma, sans-serif;
  display: flex;
  justify-content: center; 
 
}
.react-pdf__Document {
  width: 100%;
  height: auto;
}

.react-pdf__Page{
  width: 100%;
  height: auto;
}

.react-pdf__Page__canvas{
  width: 100% !important;
  height: auto!important;
}
.react-pdf__Page__annotations {
  width: 100% !important;
  height: 100% !important;
}




/* 

.Example__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
 
}


.Inner_Example{
  margin: ;
  width: 100%;
}

 */

 @media only screen and (max-width: 768px) {
  body {
    margin: 0;
    padding: 0;
    background-color: #525659;
    font-family: Segoe UI, Tahoma, sans-serif;
    display: block;
    width: 100%;
    height: auto;
  }

  .react-pdf__Document {
    width: 100%;
    height: auto;
  }

  .react-pdf__Page{
    width: 100%;
    height: auto;
  }

  .react-pdf__Page__canvas{
    width: 100% !important;
    height: auto !important;
  }
  .react-pdf__Page__annotations {
    width: 100% !important;
    height: 100% !important;
  }
 }